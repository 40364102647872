import RegionType from './regionType';

function convertRequestToCircle({ lat, lng, radius }: any) {
  return {
    center: { lat, lng },
    radius,
  };
}

function convertRequestToRectangle({ lng0, lat0, lng1, lat1 }: any) {
  return {
    bounds: [
      { lng: lng0, lat: lat0 },
      { lng: lng1, lat: lat1 },
    ],
  };
}

function convertRequestToPolygon({ points }: any) {
  return {
    points: points.map((slice: any) =>
      slice.map((point: any) => ({ lng: point[0], lat: point[1] })),
    ),
  };
}

function convertRequestToGrid({ rows, cols, ...rest }: any) {
  return {
    ...convertRequestToRectangle(rest),
    cells: { rows, cols },
  };
}

function convertRequestToMultiPolygon({ points }: any) {
  return {
    points: points.map((polygon: any) =>
      polygon.map((slice: any) =>
        slice.map((point: any) => ({ lng: point[0], lat: point[1] })),
      ),
    ),
  };
}

function convertRequestToSegmentList({ points, ids }: any) {
  return {
    ids,
    points: points.map((line: any) =>
      line.map((point: any) => ({ lng: point[0], lat: point[1] })),
    ),
  };
}

const REQUEST_TO_REGION_CONVERTERS = Object.freeze({
  [RegionType.CIRCLE]: convertRequestToCircle,
  [RegionType.RECTANGLE]: convertRequestToRectangle,
  [RegionType.POLYGON]: convertRequestToPolygon,
  [RegionType.GRID]: convertRequestToGrid,
  [RegionType.MULTIPOLYGON]: convertRequestToMultiPolygon,
  [RegionType.SEGMENT_LIST]: convertRequestToSegmentList,
});

export function convertFromDto({ flowMatrixRole, name, shape }: any) {
  return {
    name,
    flowMatrixRole,
    type: shape.type,
    shape: {
      ...REQUEST_TO_REGION_CONVERTERS[shape.type](shape),
    },
  };
}
