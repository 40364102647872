import { VehicleTypes } from 'fontModels/DataSource';
import { useMenu } from 'reducers/menuReducer';
import { RadioGroup } from 'tombac';

const options = [
  {
    label: 'All',
    value: VehicleTypes.All,
  },
  {
    label: 'Passenger',
    value: VehicleTypes.Passenger,
  },
  {
    label: 'Fleet',
    value: VehicleTypes.Fleet,
  },
];

export const VehicleTypesRadio = () => {
  const [menu, setMenu] = useMenu();

  const radioGroupOnChange = (vehicleTypes: VehicleTypes) => {
    setMenu({ vehicleTypes });
  };

  return (
    <RadioGroup
      options={options}
      variant="horizontal"
      value={options.find((it) => it.value === menu.vehicleTypes)}
      onChange={(vehicleTypes) => radioGroupOnChange(vehicleTypes.value)}
    />
  );
};
