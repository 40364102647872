export const CreateCache = <K, V>(
  hasher: (item: K) => string,
  size: number,
) => {
  const map = new Map<string, V>();

  const add = (key: K, value: V) => {
    const hash = hasher(key);
    if (map.size > size) {
      const keyToRemove = map.entries().next().value[0];
      map.delete(keyToRemove);
    }
    map.set(hash, value);
  };

  const has = (key: K) => {
    return map.has(hasher(key));
  };

  const get = (key: K) => {
    const hash = hasher(key);
    return map.get(hash);
  };
  return { add, get, has };
};
