import PropTypes from 'prop-types';
import { Component } from 'react';
import './MapControls.css';

class MapControls extends Component {
  static propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
  };

  render() {
    return (
      <div className="MapControls">
        {this.props.title && (
          <h4 className="MapControls--title">{this.props.title}</h4>
        )}
        {this.props.children}
      </div>
    );
  }
}

export default MapControls;
